@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    min-height: 100%;
    height: 100%;
    overscroll-behavior: none;
}

body {
    color: #464646;
    font-family: 'IBM Plex Mono', sans-serif;
    background-image: url("img/bg.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

.btn {
    color: #FFE149;
    background: #464646;
}

.btn:hover {
    opacity: 0.9;
}

.box {
    border-color: #464646;
    border-width: 3px;
    border-style: solid;
}

svg {
  width: 0;
  height: 0;
}

hr {
  border-color: #464646;
}
