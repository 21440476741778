@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-\[-1\] {
  z-index: -1;
}

.order-\[-1\] {
  order: -1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-\[-3rem\] {
  margin-bottom: -3rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.grid {
  display: grid;
}

.w-48 {
  width: 12rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.max-w-\[12rem\] {
  max-width: 12rem;
}

.max-w-\[3rem\] {
  max-width: 3rem;
}

.max-w-xs {
  max-width: 20rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-4 {
  border-width: 4px;
}

.border-\[\#ff0086\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 134 / var(--tw-border-opacity));
}

.bg-\[\#ff0086\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 134 / var(--tw-bg-opacity));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-\[\#200347\] {
  --tw-gradient-from: #200347 var(--tw-gradient-from-position);
  --tw-gradient-to: #20034700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#4b1a8a\] {
  --tw-gradient-to: #4b1a8a var(--tw-gradient-to-position);
}

.p-1 {
  padding: .25rem;
}

.p-5 {
  padding: 1.25rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-10 {
  opacity: .1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

html, body {
  min-height: 100%;
  height: 100%;
  overscroll-behavior: none;
}

body {
  color: #464646;
  background-image: url("bg.02b05973.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: IBM Plex Mono, sans-serif;
}

.btn {
  color: #ffe149;
  background: #464646;
}

.btn:hover {
  opacity: .9;
}

.box {
  border: 3px solid #464646;
}

svg {
  width: 0;
  height: 0;
}

hr {
  border-color: #464646;
}

.hover\:bg-\[\#fc3aa1\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 58 161 / var(--tw-bg-opacity));
}

@media not all and (min-width: 768px) {
  .max-md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

@media (min-width: 640px) {
  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

@media (min-width: 768px) {
  .md\:fixed {
    position: fixed;
  }

  .md\:top-\[initial\] {
    top: initial;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:m-8 {
    margin: 2rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:w-\[initial\] {
    width: initial;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:opacity-10 {
    opacity: .1;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:opacity-20 {
    opacity: .2;
  }

  .md\:opacity-30 {
    opacity: .3;
  }

  .md\:opacity-40 {
    opacity: .4;
  }

  .md\:opacity-5 {
    opacity: .05;
  }

  .md\:opacity-50 {
    opacity: .5;
  }

  .md\:opacity-60 {
    opacity: .6;
  }

  .md\:opacity-70 {
    opacity: .7;
  }

  .md\:opacity-80 {
    opacity: .8;
  }

  .md\:opacity-90 {
    opacity: .9;
  }
}

@media (min-width: 1024px) {
  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }
}

.\[\&\>span\:nth-child\(even\)\]\:text-\[16\.7vw\] > span:nth-child(2n) {
  font-size: 16.7vw;
}

.\[\&\>span\:nth-child\(even\)\]\:font-medium > span:nth-child(2n) {
  font-weight: 500;
}

@media (min-width: 768px) {
  .md\:\[\&\>span\:nth-child\(even\)\]\:text-\[8\.2vw\] > span:nth-child(2n) {
    font-size: 8.2vw;
  }
}

.\[\&\>span\:nth-child\(odd\)\]\:text-\[12vw\] > span:nth-child(2n+1) {
  font-size: 12vw;
}

.\[\&\>span\:nth-child\(odd\)\]\:font-light > span:nth-child(2n+1) {
  font-weight: 300;
}

@media (min-width: 768px) {
  .md\:\[\&\>span\:nth-child\(odd\)\]\:text-\[6vw\] > span:nth-child(2n+1) {
    font-size: 6vw;
  }
}

.\[\&\>span\]\:block > span {
  display: block;
}

.\[\&\>span\]\:leading-none > span {
  line-height: 1;
}

/*# sourceMappingURL=index.e2578b04.css.map */
